import React from "react";
import { Link } from "react-router-dom";

const L3Footer = () => {
  return (
    <div className="l3-footer">
      <div className="upper_section">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div className="footer_title">
                <h5>Location</h5>
              </div>
              <div className="footer_info">
                <p>717 S 38th St</p>
                <p>Tacoma, WA 98418</p>
              </div>
            </div>
            <div className="col-4">
              <div className="footer_title">
                <h5>Contact us</h5>
              </div>
              <div className="footer_info">
                <p>(253) 473-4776</p>
              </div>
            </div>
            <div className="col-4 footer_logo">
              <Link to="/">
                <img src="/img/landing3/logo-p.png" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-md-flex justify-content-between align-items-center mb-5">
                <div className="copyright text-center text-md-start mt-3">
                  Copyright <span style={{ fontSize: "16px" }}>&copy;</span>{" "}
                  {new Date().getFullYear()} Phuong Beauty Salon
                </div>
                <div className="policy d-flex d-md-block justify-content-between mt-3">
                  <p className="d-inline-block me-4">Privacy & Policy</p>
                  <p className="d-inline-block">Terms of use</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default L3Footer;
