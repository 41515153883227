import { useQuery } from "@apollo/react-hooks";
import { Table, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { DynamicList } from "../components/Shared";
import { FETCH_ALL_APPOINTMENT } from "../graphql/modules";
import { FullName } from "../util/helper";

const AppointmentQue = () => {
  const [limit] = useState(10);
  const [page, setPage] = useState(1);

  const { data, loading } = useQuery(FETCH_ALL_APPOINTMENT, {
    variables: {
      filterData: {
        status: "Upcoming",
      },
      fromWaitList: true,
      limit,
      offset: page - 1,
    },
  });
  const allAppointment = data?.FetchApointmentList?.apointment || [];
  const total = data?.FetchApointmentList?.count || 0;

  // page handler
  const onChangePagination = (p) => {
    setPage(p);
  };

  const columns = [
    {
      title: "Name",
      render: (text, record) =>
        FullName({
          firstname: record?.user?.firstname,
          lastname: record?.user?.lastname,
        }),
    },
    {
      title: "Appointment Date",
      dataIndex: "date",
      render: (text) => moment(text, "x").local().format("MM-DD : hh:mm A"),
    },
    { title: "Number of Guests", dataIndex: "numOfPeople" },
    {
      title: "Service(s)",
      render: (_, record) => (
        <div className="d-">
          {record?.menu?.map((item) => (
            <Tag
              className="d-block mb-2 text-center"
              color="green"
              key={item?._id}
            >
              {item?.name}
            </Tag>
          ))}
        </div>
      ),
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (text) => <Tag color={getStatusColor(text)}>{text}</Tag>,
    // },
  ];

  return (
    <DynamicList title="Waitlist" tableHide>
      <section id="appointment-que">
        <div className="container-fluid">
          <div className="row">
            <div className="appointment-que">
              <Table
                className="que-table"
                columns={columns}
                dataSource={allAppointment}
                loading={loading}
                scroll={{ x: true }}
                rowKey={(row) => row?._id}
                pagination={{
                  total,
                  current: page,
                  showSizeChanger: false,
                  pageSize:limit,
                  onChange: onChangePagination
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </DynamicList>
  );
};

export default AppointmentQue;

// const getStatusColor = (key) => {
//   switch (key) {
//     case "Upcoming":
//       return "#108ee9";
//     case "Ongoing":
//       return "#87d068";
//     case "Upcoming":
//       return "#2db7f5";
//     case "Cancelled":
//       return "#f50";
//     default:
//       return "green";
//   }
// };
