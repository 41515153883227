import React from "react";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";

// import required modules
import { Navigation } from "swiper";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Rate } from "antd";

const L3Testimonial = () => {
  return (
    <div className="l3-testimonials" id="l3-testimonials">
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h2 className="l3-section-title">What Our Client Say</h2>
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              navigation={{
                prevEl: "#prev_btn",
                nextEl: "#next_btn",
              }}
              modules={[Navigation]}
              className="mySwiper"
            >
              {testimonials.map(({ text, name, imageSrc, value }, idx) => (
                <SwiperSlide className="single_testimonial" key={idx}>
                  <div className="review_text_box">
                    <p>{text}</p>
                    <div className="quota" />
                  </div>
                  <div className="user_identity justify-content-end justify-content-lg-center">
                    <img src={imageSrc} alt="image" />
                    <div>
                      <p className="name">{name}</p>
                      <Rate
                        className="user-rating"
                        disabled
                        defaultValue={value}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="next_prev_icons">
              <div className="next_prev_icons_inner">
                <LeftOutlined id="prev_btn" />
                <RightOutlined id="next_btn" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default L3Testimonial;

const testimonials = [
  {
    text: "My mom use to come here all the time when I was younger, and now I know why! Amazing haircut!",
    imageSrc: "/img/landing3/william.png",
    name: "Lakhina Voeuk",
    value: 5,
  },
  {
    name: "Marlene Mankin",
    text: "Mani-Pedi, GREAT service!",
    imageSrc: "/img/landing3/tanisa.png",
    value: 4,
  },
  {
    name: "TT Phan",
    text: "Awesome💙",
    imageSrc: "/img/landing3/davis.png",
    value: 5,
  },
  {
    name: "Jeniffer Scitern",
    text: "Good",
    imageSrc: "/img/landing3/erika.png",
    value: 4,
  },
];
