import React from "react";
import { BasicInfo, EmailChange, PasswordChange } from "../components";
import { DynamicList } from "../components/Shared";

const MyProfile = () => {
  return (
    <div id="myprofile-page">
      <DynamicList title="Profile" tableHide>
        <BasicInfo />
      </DynamicList>
      <DynamicList title="Security" tableHide>
        <div className="row">
          <EmailChange />
          <PasswordChange />
        </div>
      </DynamicList>
    </div>
  );
};

export default MyProfile;
