import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";

const PasswordChangeCon = () => {
  return (
    <section id="email-change-confirmation">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-6">
            <div className="text-center">
              <CheckCircleOutlined className="pass-check-mark" />
            </div>
            <div className="title">
              <h1>Password Changed!</h1>
            </div>
            <div className="message">
              <p>Your password has been changed successfully.</p>
            </div>
            <div className="text-center">
               <Link to="/signin">Login</Link>
            </div>
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default PasswordChangeCon;
