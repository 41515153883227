import { useMutation } from "@apollo/react-hooks";
import { Button, Card, Result, Spin } from "antd";
import React from "react";
import { USER_UNSUBSCRIBER } from "../graphql/modules";
import { Link } from "react-router-dom";
import { successNotify } from "../util";

const Unsubscribe = ({ location, history }) => {

let msg = "To unsubscribe to our newsletter, click the button below"
  const query = new URLSearchParams(location.search);

  const [UnsubscribeMutation, { loading }] =
    useMutation(USER_UNSUBSCRIBER);

  const onUnsubscribe = async () => {
    try {
      const {
        data: {UnsubscribeForEmail},
      } = await UnsubscribeMutation({
        variables: {
          id: query.get("id"),
        },
      });
      if(UnsubscribeForEmail.success){
        successNotify(UnsubscribeForEmail?.message);
        history.push('/')
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="section login-area">
      <div className="container_standard">
        <div className="inner-login">
          <div id="auth-section">
            <div className="container">
              <div className="row justify-content-around">
                <div
                  className="col-md-6 col-sm-12"
                  style={{
                    marginBottom: "200px",
                    marginTop: "250px",
                  }}
                >
                  <Card>
                    <Spin spinning={loading}>
                      <Result
                        className="d-flock"
                        status={"403"}
                        title={<div style={{ color: "#000" }}>{msg}</div>}
                      />
                      <div className="text-center">
                        <Button type="primary" onClick={onUnsubscribe}>Unsubscribe</Button>
                      </div>
                    </Spin>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unsubscribe;
