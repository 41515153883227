import { CheckCircleOutlined } from "@ant-design/icons";
import React from "react";

const Benefit = () => {
  return (
    <div className="l3-benefit">
      <div className="container">
        <div className="row gx-5 gy-5">
          <div className="col-md-6">
            <div className="benefit-img">
              <img src="/img/landing3/choose.png" />
            </div>
          </div>
          <div className="col-md-1 d-none d-lg-block"></div>
          <div className="col-md-5">
            <h2 className="l3-section-title">Why choose us?</h2>
            {descriptionList.map((chs) => (
              <div className="l3-benefit-content mb-4" key={chs?.title}>
                <div className="l3-mini-title">
                  <h6>
                    <CheckCircleOutlined /> {chs.title}
                  </h6>
                </div>
                <p>{chs.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefit;

const descriptionList = [
  {
    title: "Experienced and Skilled Stylists",
    description:
      "Our stylists are highly trained and experienced, ensuring you receive the highest quality hair and beauty services.",
  },
  {
    title: "Customized Services",
    description:
      "We take the time to understand your unique needs and preferences, providing customized services to meet your individual requirements.",
  },
  {
    title: "Relaxing Atmosphere",
    description:
      "Our salon is designed to provide a warm, inviting, and relaxing environment where you can unwind and feel rejuvenated.",
  },
  {
    title: "Wide Range of Services",
    description:
      "We offer a comprehensive range of hair and beauty services, including haircuts, perms, highlights, facials, and more, so you can find exactly what you need in one convenient location.",
  },
];
