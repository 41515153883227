import { Button } from "antd";
import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { RESEND_CHANGE_EMAIL_REQUEST } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";

const EmailVerifyDialogue = () => {
  const { id } = useParams();

  const [ResendEmailChangeRequest] = useMutation(RESEND_CHANGE_EMAIL_REQUEST);
  const onRequest = async () => {
    try {
      const {
        data: { ResendChangeEmail },
      } = await ResendEmailChangeRequest({
        variables: {
          securityCode: id,
        },
      });
      if (ResendChangeEmail?.success) {
        successNotify(ResendChangeEmail.message);
      } else {
        warnNotify(ResendChangeEmail.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <section id="email-change-confirmation">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-6">
            <div className="title">
              <h1>Verify Your Email</h1>
            </div>

            <div className="message">
              <p>
                Please check your email for a link to verify your email address.
              </p>
              <p>Once verified, you'll be able to continue.</p>
            </div>
            <div className="verify-icon">
              <i className="far fa-envelope">
                <CheckCircleFilled className="right-mark" />
              </i>
            </div>
            <div className="message">
              <p>
                Didn't recieve an email?
                <Button
                  style={{
                    border: "none",
                    color: "#096dd9",
                    fontWeight: "600",
                    padding: "0 5px",
                  }}
                  onClick={onRequest}
                >
                  Resend
                </Button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmailVerifyDialogue;
