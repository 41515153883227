import { MailOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { Button, Card, Input } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UPDATE_EMAIL } from "../../graphql/modules";
import { logoutUser } from "../../store/modules/auth";
import { formValidator, warnNotify } from "../../util";

const EmailChange = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state?.auth?.user);

  const [readOnly, setReadOnly] = useState(true);
  const [userData, setUserData] = useState({
    email: "",
  });
  const [error, setError] = useState({
    email: "",
  });
  const [password, setPassword] = useState("");

  const [UpdateEmailMutation, { loading: emailLoading }] =
    useMutation(UPDATE_EMAIL);
  const onUpdateEmail = async () => {
    try {
      const {
        data: { ChangeEmail },
      } = await UpdateEmailMutation({
        variables: {
          newEmail: userData?.email,
          password,
        },
      });
      if (ChangeEmail.success) {
        // successNotify(ChangeEmail?.message);
        dispatch(logoutUser());
        history.push(`/email-verify-confirmation/${ChangeEmail?.token}`);
      } else {
        warnNotify(ChangeEmail?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    formValidator({ name, value, userData, error, setError });
    setUserData({ ...userData, [name]: value });
  };

  return (
    <div className="col-md-6 mb-3">
      <Card
        className="profile-card"
        style={{ height: "100%" }}
        title={
          <div className="d-flex align-items-center justify-content-center">
            <MailOutlined className="me-2" />
            CHANGE EMAIL
          </div>
        }
        hoverable
      >
        <div className="row">
          <div className="common-input">
            <div>
              <label>Current email</label>
            </div>
            <Input size="large" disabled value={user?.email} />
          </div>
        </div>
        <div className="row">
          <div className="common-input">
            <div>
              <label>New email</label>
            </div>
            <Input
              size="large"
              placeholder="New email address"
              name="email"
              type="text"
              readOnly={readOnly}
              onFocus={() => setReadOnly(false)}
              onBlur={() => setReadOnly(true)}
              value={userData?.email}
              onChange={handleInput}
            />
            {error?.email && (
              <small style={{ color: "orangered" }}>
                Email cannot be empty and must be valid!
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="common-input">
            <div>
              <label>Password</label>
            </div>
            <Input
              size="large"
              value={password}
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-3 text-center">
            <Button
              size="large"
              type="primary"
              // style={{ width: "100%" }}
              disabled={!userData?.email || !password || error?.email}
              loading={emailLoading}
              onClick={onUpdateEmail}
            >
              Update Email
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default EmailChange;
