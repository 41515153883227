import React from "react";
import {
  Grow,
  L3Hero,
  L3Services,
  // L3Step,
  Benefit,
  // L3Gallery,
  L3Footer,
  L3Header,
  L3Testimonials,
  L3ContactUs,
} from "../components";

const LandingPage3 = () => {
  return (
    <div id="landing_page3">
      <L3Header />
      <L3Hero />
      <L3Services />
      <Grow />
      <Benefit />
      <L3Testimonials />
      <L3ContactUs />
      <L3Footer />
    </div>
  );
};

export default LandingPage3;
