import { UploadOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Card, DatePicker, Input, Spin, Switch } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  FETCH_USER_BY_ID,
  FILE_UPLOAD,
  PROFILE_UPDATE,
} from "../../graphql/modules";
import { onUpdateProfile } from "../../store/modules/auth";
import {
  formValidator,
  getImageUser,
  successNotify,
  warnNotify,
} from "../../util";

const BasicInfo = () => {
  const { user } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();

  const [userInput, setUserInput] = useState({});
  // {
  //   firstname: user?.firstname,
  //   lastname: user?.lastname,
  //   phone: user?.phone,
  //   birthDate: moment(user?.birthDate, "x").local(),
  //   avater: user?.avater,
  //   isSubscribed: user?.isSubscribed,
  // }
  const {
    data,
    loading: fechLoading,
    refetch,
  } = useQuery(FETCH_USER_BY_ID, {
    variables: { id: user._id },
  });
  const userData = data?.FetchUserById?.user || {};
  useEffect(() => {
    if (userData?._id) {
      setUserInput({
        firstname: userData?.firstname,
        lastname: userData?.lastname,
        phone: userData?.phone,
        birthDate: moment(userData?.birthDate, "x").local(),
        avater: userData?.avater,
        isSubscribed: userData?.isSubscribed,
      });
    }
  }, [userData]);

  console.log({ userData, user });

  const [error, setError] = useState({
    phone: "",
    firstname: "",
    lastname: "",
  });
  // updatre profile
  const [UpdateProfileMutation, { loading }] = useMutation(PROFILE_UPDATE);
  const onSubmit = async () => {
    const data = { ...userInput };
    if (user?.phone === userInput?.phone) {
      delete data.phone;
    }
    try {
      const {
        data: { ProfileUpdate },
      } = await UpdateProfileMutation({
        variables: {
          userInput: {
            ...data,
            birthDate: moment(userInput?.birthDate).local().format("x"),
          },
        },
      });
      if (ProfileUpdate.success) {
        refetch();
        successNotify(ProfileUpdate?.message);
        dispatch(
          onUpdateProfile({
            ...userInput,
            birthDate: moment(userInput?.birthDate).local().format("x"),
          })
        );
      } else {
        warnNotify(ProfileUpdate.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [FileUploadMutation, { loading: fileLoading }] =
    useMutation(FILE_UPLOAD);
  const handleUploadImage = async (e) => {
    try {
      const {
        data: { SingleUpload },
      } = await FileUploadMutation({
        variables: {
          file: e.target.files[0],
        },
      });
      if (SingleUpload.success) {
        setUserInput({ ...userInput, avater: SingleUpload?.filename });
      } else {
        warnNotify(SingleUpload?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
    formValidator({ name, value, userData: userInput, error, setError });
  };

  const handleDate = (value) => {
    setUserInput({ ...userInput, birthDate: moment(value, "x").local() });
    if (!value) {
      setUserInput({
        ...userInput,
        birthDate: moment(user?.birthDate, "x").local(),
      });
    }
  };

  const disable =
    !userInput?.firstname ||
    !userInput?.lastname ||
    !userInput?.birthDate ||
    !userInput?.phone ||
    error?.firstname ||
    error?.lastname ||
    error?.phone ||
    fileLoading ||
    loading;

  return (
    <Spin spinning={fechLoading}>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mb-3 common-input">
              <div>
                <label>First Name</label>
              </div>
              <Input
                size="large"
                onChange={handleInput}
                name="firstname"
                value={userInput?.firstname}
              />
              {error?.firstname && (
                <small style={{ color: "orangered" }}>
                  Name is cannot be empty and must be valid!
                </small>
              )}
            </div>
            <div className="col-md-4 mb-3 common-input">
              <div>
                <label>Last Name</label>
              </div>
              <Input
                size="large"
                onChange={handleInput}
                name="lastname"
                value={userInput?.lastname}
              />
              {error?.lastname && (
                <small style={{ color: "orangered" }}>
                  Name is cannot be empty and must be valid!
                </small>
              )}
            </div>
            <div className="col-md-4 mb-3 common-input">
              <div>
                <label>Phone</label>
              </div>
              <Input
                size="large"
                onChange={handleInput}
                name="phone"
                value={userInput?.phone}
              />
              {error.phone && (
                <small style={{ color: "orangered" }}>
                  Must be a valid phone number!
                </small>
              )}
            </div>
            <div className="col-md-4 mb-3 common-input">
              <div>
                <label>Birthdate</label>
              </div>
              {userInput?.birthDate && (
                <DatePicker
                  size="large"
                  format="MM-DD-YYYY"
                  value={userInput?.birthDate}
                  onChange={handleDate}
                  style={{ width: "100%" }}
                />
              )}
            </div>
            <div className="col-md-4 mb-3 common-input">
              <div>
                <label>Subscribe for news and updates</label>
              </div>
              <Switch
                onChange={(checked) =>
                  setUserInput({ ...userInput, isSubscribed: checked })
                }
                checkedChildren="On"
                unCheckedChildren="Off"
                checked={userInput?.isSubscribed}
              />
            </div>
            {/* </div>
          <div className="row"> */}
            {/* <div className="col-md-4 mb-3 common-input"></div> */}
            <div className="col-md-4 mb-3 common-input">
              <Spin spinning={fileLoading}>
                <div className="mt-2">
                  <label>Profile picture</label>
                </div>
                <div className="text-center">
                  <img
                    src={getImageUser(userInput?.avater)}
                    style={{
                      width: "150px",
                      borderRadius: "50%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                    // onError={getImageUser()}
                  />
                  <div className="mt-3 d-flex justify-content-center">
                    <Button className="d-flex justify-content-between align-items-center position-relativea">
                      Upload <UploadOutlined />
                      <input
                        className="position-absolute"
                        style={{ width: "100%", height: "100%", opacity: 0 }}
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        onChange={handleUploadImage}
                      />
                    </Button>
                  </div>
                </div>
              </Spin>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-3 text-center">
              <Button
                style={{ width: "100%" }}
                type="primary"
                onClick={onSubmit}
                loading={loading}
                size="large"
                disabled={disable}
              >
                Update
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default BasicInfo;
