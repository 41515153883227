import React from "react";

const Grow = () => {
  return (
    <div className="l3-grow" id="l3-grow">
      <div className="container">
        <div className="row gx-5">
          <div className="col-md-4">
            <h2 className="l3-section-title">
              We are <br />
              Committed
            </h2>
            <p className="mb-5">
              Our salon offers a warm, welcoming atmosphere where you can relax,
              unwind, and rejuvenate. Whether you're in need of a fresh new
              haircut, a luxurious facial, or a stylish perm, our team is here
              to help you look and feel your best. Come visit us at Phuong
              Beauty Salon and let us help you reveal your inner beauty.
            </p>
            {/* <a role="button" className="l3-btn">
              Read More
            </a> */}
          </div>
          <div className="col-md-8">
            <div className="grow-bg">
              <img src="/img/landing3/grow-bg.png" />
            </div>
            {/* <div className="row mt-5">
              <div className="col-6">
                <div className="l3-grow-right">
                  <div className="before-title" />
                  <div className="content">
                    <div className="l3-mini-title">
                      <h6>Title 1</h6>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Dolorem odit voluptatem corporis similique vero obcaecati
                      alias quasi sequi facere mollitia.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="l3-grow-right">
                  <div className="before-title" />
                  <div className="content">
                    <div className="l3-mini-title">
                      <h6>Title 2</h6>
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Dolorem odit voluptatem corporis similique vero obcaecati
                      alias quasi sequi facere mollitia.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grow;
