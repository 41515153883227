import React from "react";

const L3Hero = () => {
  return (
    <section className="l3-hero" id="l3-hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="hero-inner-left d-flex align-items-center">
              <div className="left-content">
                <h1 className="mb-3">Welcome to Phuong Beauty Salon</h1>
                <p className="mb-5">
                  where we provide you with professional hair care services in a
                  relaxing and rejuvenating environment. Our highly trained
                  stylists are dedicated to giving you the perfect look,
                  customized just for you. Come visit us today and let us take
                  care of your hair needs.
                </p>
                <div>
                  <a className="l3-btn" role="button" href="tel:(253) 473-4776">
                   Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <div className="hero-inner-right d-flex align-items-center">
              {/* <div className="right-content">
                <div className="l3-mini-title">
                  <h6>Sample Title</h6>
                </div>
                <p className="mb-2">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                  totam error soluta
                </p>
                <a role="button">
                  Read More <ArrowRightOutlined />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default L3Hero;
