import { useMutation } from "@apollo/react-hooks";
import { Card, Result, Spin } from "antd";
import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { AuthLayout } from "../containers";
// import { logoutUser } from "../store/modules";
import { CONFIRM_EMAIL_CHANGE } from "../graphql/modules";
import { Link } from "react-router-dom";

const NewEmailVerify = ({ location, history }) => {
  // const dispatch = useDispatch();

  // const query = new URLSearchParams(location.search);
  // const params = Object.fromEntries(query);
  // const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const params = Object.fromEntries(query);

  const [VerifyEmailChnageCodeMutation, { loading, data }] =
    useMutation(CONFIRM_EMAIL_CHANGE);
  useEffect(() => {
    // dispatch(logoutUser());
    VerifyEmailChnageCodeMutation({
      variables: {
        securityCode: params.security_code || "",
      },
    });
  }, []);

  // const [VerifyEmailChnageCodeMutation, { loading, data }] =
  //   useMutation(CONFIRM_EMAIL_CHANGE);
  // const deleteFaq = async () => {
  //   try {
  //     const {
  //       data: { ConfirmChangeEmail },
  //     } = await VerifyEmailChnageCodeMutation({
  //       variables: {
  //         securityCode: params.security_code || "",
  //       },
  //     });
  //     if (ConfirmChangeEmail.success) {
  //     } else {
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  let status = "403";
  let msg = "Security code verifing...";

  if (data?.ConfirmChangeEmail?.success) {
    status = "success";
    msg = data?.ConfirmChangeEmail?.message;
  } else if (data?.ConfirmChangeEmail?.success === false) {
    status = "error";
    msg = data?.ConfirmChangeEmail?.message;
  }

  if (data?.ConfirmChangeEmail?.success && params.requirePassword === "true") {
    setTimeout(() => {
      msg = "Redirecting...";
    }, 1000);
    setTimeout(() => {
      return history.push("/login");
    }, 3000);
  }
  return (
    <div className="container">
      <div className="row justify-content-around">
        <div
          className="col-md-4 col-sm-12"
          style={{
            // marginBottom: "200px",
            // marginTop: "250px",
            padding:"150px 0"
          }}
        >
          <Card bordered={false}>
            <Spin spinning={loading}>
              <Result
                className="d-flock"
                status={status}
                title={<div style={{ color: "#000" }}>{msg}</div>}
              />
              <div className="text-center">
                <Link to="/signin" className="d-block" style={{fontWeight:"bold"}}>
                  Login
                </Link>
              </div>
            </Spin>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default NewEmailVerify;
