import { useQuery } from "@apollo/react-hooks";
import { Button, Card, DatePicker, Input, Pagination, Select, Table, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DynamicList, PageTitle } from "../components/Shared";
import { FETCH_ALL_APPOINTMENT, FETCH_ALL_MENU } from "../graphql/modules";
import { EditAppointment } from "../components";
import { appointmentStatus } from "../config/constants";

const Dashboard = () => {
  const { user } = useSelector((state) => state?.auth);

  const [filterData, setFilterData] = useState({});
  const [filterState, setFilterState] = useState({});

  const [limit] = useState(10);
  const [page, setPage] = useState(1);

  const { data, loading, refetch } = useQuery(FETCH_ALL_APPOINTMENT, {
    variables: {
      userId: user?._id,
      filterData,
      limit,
      offset: page - 1,
    },
  });
  const allAppointment = data?.FetchApointmentList?.apointment || [];
  const total = data?.FetchApointmentList?.count || 0;


  // fetch all menu
  const { data: meu } = useQuery(FETCH_ALL_MENU);
  const allMenuList = meu?.FetchMenuList?.menu || [];

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilterState({ ...filterState, [name]: value });
  };

  const handleDatePicker = (data) => {
    setFilterState({
      ...filterState,
      dateStart: moment(data[0]).local().format("x"),
      dateEnd: moment(data[1]).local().format("x"),
    });
  };

  const handleClearFilter = () => {
    setFilterData({});
    setFilterState({});
  };

    // page handler
    const onChangePagination = (p) => {
      setPage(p);
    };

  const columns = [
    {
      title: "Appointment Date",
      dataIndex: "date",
      render: (text) => moment(text, "x").local().format("MM-DD-YYYY : hh:mm A"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Service Name",
      render: (_, record) => (
        <div className="d-">
          {record?.menu?.map((item) => (
            <Tag
              className="d-block mb-2 text-center"
              color="green"
              key={item?._id}
            >
              {item?.name}
            </Tag>
          ))}
        </div>
      ),
    },
    { title: "Number of guest", dataIndex: "numOfPeople" },
    // { title: "Price", render: (_, record) => record?.menu?.price },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => <Tag color={getStatusColor(text)}>{text}</Tag>,
    },
    {
      title: "Edit Appointment",
      render: (_, record) => (
        <div>
          {record?.status === "Upcoming" ? (
            <EditAppointment
              data={record}
              refetch={refetch}
              allMenuList={allMenuList}
            />
          ) : (
            "--"
          )}
        </div>
      ),
    },
  ];

  const disable =
    !filterState.phone &&
    (!filterState.dateStart || !filterState?.dateEnd) &&
    !filterState.status;

  const tableFilter = (
    <div>
      <div className="row">
        <div className="col-md-4 common-input">
          <div>
            <label>Date Range</label>
          </div>
          <DatePicker.RangePicker
            value={[
              filterState?.dateStart ? moment(filterState?.dateStart, "x").local() : "",
              filterState?.dateEnd ? moment(filterState?.dateEnd, "x").local() : "",
            ]}
            format="MM-DD-YYYY"
            onChange={handleDatePicker}
            style={{ width: "100%" }}
            clearIcon={false}
          />
        </div>
        <div className="col-md-4 common-input">
          <div>
            <label>Phone</label>
          </div>
          <Input
            name="phone"
            onChange={handleFilter}
            value={filterState?.phone || ""}
            placeholder="Search by phone"
          />
        </div>
        <div className="col-md-4 common-input">
          <div>
            <label>Status</label>
          </div>
          <Select
            style={{ width: "100%" }}
            onChange={(value) =>
              setFilterState({ ...filterState, status: value })
            }
            value={filterState?.status || ""}
          >
            <Select.Option key="">Any</Select.Option>
            <Select.Option key={appointmentStatus.Upcoming}>
              {appointmentStatus.Upcoming}
            </Select.Option>
            <Select.Option key={appointmentStatus.Ongoing}>
              {appointmentStatus.Ongoing}
            </Select.Option>
            <Select.Option key={appointmentStatus.Completed}>
              {appointmentStatus.Completed}
            </Select.Option>
            <Select.Option key={appointmentStatus.Cancelled}>
              {appointmentStatus.Cancelled}
            </Select.Option>
          </Select>
        </div>
      </div>
      <div className="row">
        <div className="text-end">
          <Button
            type="primary"
            className="me-2"
            onClick={() => setFilterData({ ...filterState })}
            disabled={disable}
          >
            Filter
          </Button>
          <Button onClick={handleClearFilter}>Clear</Button>
        </div>
      </div>
    </div>
  );

  return (
    <DynamicList
      title="Appointment History"
      columns={columns}
      data={allAppointment}
      loading={loading}
      tableFilter={tableFilter}
      pagination={
        <Pagination
          className="pagination"
          total={total}
          pageSize={limit}
          current={page}
          showSizeChanger={false}
          onChange={onChangePagination}
        />
      }
    />
  );
};

export default Dashboard;

const getStatusColor = (key) => {
  switch (key) {
    case "Upcoming":
      return "#108ee9";
    case "Ongoing":
      return "#87d068";
    case "Upcoming":
      return "#2db7f5";
    case "Cancelled":
      return "#f50";
    default:
      return "green";
  }
};
