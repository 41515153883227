import { useMutation } from "@apollo/react-hooks";
import { Button, Card, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { UPDATE_PASSWORD } from "../../graphql/modules";
import { logoutUser } from "../../store/modules/auth";
import { formValidator, successNotify, warnNotify } from "../../util";
import { SecurityScanOutlined } from "@ant-design/icons";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const PasswordChange = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const [cpHide, setCpHide] = useState(true);
  const [pHide, setPHide] = useState(true);
  const [changePassword, setChangePassword] = useState({
    oldPassword: "",
    password: "",
  });
  const [cpassword, setCpassword] = useState("");

  const [error, setError] = useState({
    password: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setChangePassword({ ...changePassword, [name]: value });
    formValidator({ name, value, userData: changePassword, error, setError });
  };

  const [PasswordChangeMutation, { loading }] = useMutation(UPDATE_PASSWORD);
  const onUpdatePassword = async () => {
    try {
      const {
        data: { PassowrdUpdate },
      } = await PasswordChangeMutation({
        variables: {
          oldPassword: changePassword?.oldPassword,
          newPassword: changePassword?.password,
        },
      });
      if (PassowrdUpdate?.success) {
        successNotify(PassowrdUpdate?.message);
        dispatch(logoutUser());
        history.push("/password-change-confirmation");
      } else {
        warnNotify(PassowrdUpdate?.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const disable =
    !changePassword?.password ||
    !changePassword?.oldPassword ||
    changePassword?.password !== cpassword ||
    error?.password;

  return (
    <div className="col-md-6">
      <Card
        className="profile-card"
        title={
          <div className="d-flex align-items-center justify-content-center">
            <SecurityScanOutlined className="me-2" /> Change Password
          </div>
        }
        hoverable
      >
        <div className="row">
          <div className="common-input">
            <div>
              <label>Current password</label>
            </div>
            <Input
              size="large"
              type="password"
              placeholder="Current password"
              name="oldPassword"
              onChange={handleInput}
              value={changePassword?.oldPassword}
            />
          </div>
          <div className="common-input">
            <div>
              <label>New password</label>
            </div>
            <Input
              size="large"
              placeholder="New password"
              type={pHide ? "password" : ""}
              value={changePassword?.password}
              name="password"
              onChange={handleInput}
              suffix={
                pHide ? (
                  <EyeInvisibleOutlined onClick={() => setPHide(false)} />
                ) : (
                  <EyeOutlined onClick={() => setPHide(true)} />
                )
              }
            />
            {error?.password && (
              <small style={{ color: "orangered" }}>
                Password cannot be empty and must be a minimum of 8 characters!
              </small>
            )}
          </div>
          <div className="common-input">
            <div>
              <label>Confirm password</label>
            </div>
            <Input
              size="large"
              placeholder="Confirm password"
              value={cpassword}
              type={cpHide ? "password" : ""}
              onChange={(e) => setCpassword(e.target.value)}
              suffix={
                cpHide ? (
                  <EyeInvisibleOutlined onClick={() => setCpHide(false)} />
                ) : (
                  <EyeOutlined onClick={() => setCpHide(true)} />
                )
              }
            />
            {cpassword && changePassword?.password !== cpassword && (
              <small style={{ color: "orangered" }}>
                Password doesn't match!
              </small>
            )}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-4 text-center">
            <Button
              size="large"
              type="primary"
              // style={{ width: "100%" }}
              loading={loading}
              onClick={onUpdatePassword}
              disabled={disable}
            >
              Update Password
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PasswordChange;
