import React from "react";

const Footer = () => {
  return (
    <div id="landing_footer" className="pt-5 pb-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 col-lg-5">
            <div className="footer_left">
              <div className="row">
                <div className="col-6 col-lg-12">
                  <div className="mb-5">
                    <h2 className="section_title">
                      <strong>LOCATION</strong>
                    </h2>
                    <p className="address">Perfect Nail Salon & Spa</p>
                    <p className="address">
                      Canyon Rd E #44, Puyallup, WA 98373
                    </p>
                    <a
                      style={{ color: "#cd815d", marginTop: "20px" }}
                      href="tel:(253) 536-8976"
                      className="phone"
                    >
                      (253) 536-8976
                    </a>
                  </div>
                </div>
                <div className="col-6 col-lg-12">
                  <div className="text-end text-lg-start">
                    <h2 className="section_title">
                      <strong>FOLLOW US</strong>
                    </h2>
                    <div className="icon_box">
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/perfectnailsspasalon/ "
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a
                        target="_blank"
                        href="https://www.snapchat.com/add/perfectnailswa?share_id=bez815Xftdo&locale=en-US"
                      >
                        <i className="fab fa-snapchat-square"></i>
                      </a>
                    </div>
                    <p className="privacy_para">
                      &#x40; {new Date().getFullYear()} Privacy policy
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 d-none d-lg-block">
            <div className="right_box">
              <img src="/img/landing_img/footer.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
