import React from "react";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <section id="email-change-confirmation">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-6">
            <div>
              <h4>Privacy Policy</h4>
            </div>
            <div className="mt-3">
              <p className="mb-2">
                This page informs you of our policies regarding the collection,
                use and disclosure of Personal Information we receive from users
                of the Site.
              </p>
              <p>
                We use your Personal Information only for providing and
                improving the Site. By using the Site, you agree to the
                collection and use of information in accordance with this
                policy.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-4 justify-content-around">
          <div className="col-md-6">
            <div>
              <h4>Information Collection and Use</h4>
            </div>
            <div className="mt-3">
              <p className="mb-2">
                While using our site, we may ask you to provide us with certain
                personally identifiable information that can be used to contact
                or identify you. Personally, Identifiable information may
                include but is not limited to your name, email address, phone
                number, and birthday.
              </p>

              <div className="mt-4"><Link to="/signup">Go Back</Link></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Terms;
