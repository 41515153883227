import React from "react";
import { Delux, Footer, Header, Services } from "../components";

const LandingPage = () => {
   window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction=()=> {
    if (
      document.body.scrollTop > 101 ||
      document.documentElement.scrollTop > 101
    ) {
      document.getElementById("landing_header").classList.add("navScroll");
    } if(document.documentElement.scrollTop<80){
      document.getElementById("landing_header").classList.remove("navScroll");
    }
  }

  return (
    <div className="landing_page">
      <div className="landing_header" id="landing_header">
        <Header />
      </div>
      {/* hero section */}
      <div id="hero_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="hero_outter">
                <h2>Perfect Nails & Spa Salon</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about section */}
      <div id="about" className="pb-5">
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section_title mb-4">
                We offer vip <strong>nail services</strong>
              </h2>
            </div>
            <div className="col-md-7">
              <div className="about_left">
                <img
                  className="img-fluid"
                  src="/img/landing_img/about_bg.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <div className="mt-4">
                <div className="about_right">
                  <div className="about_right_title">
                    <h2>For all your hands and feet do for you</h2>
                  </div>
                  <p className="mt-5 mb-5">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Libero quas nisi repellat voluptatem, praesentium, molestiae
                    consequuntur incidunt quaerat cumque distinctio modi. Libero
                    ipsam consectetur fugit consequuntur laboriosam enim et
                    vitae?
                  </p>
                  <a
                    href="tel:(253) 536-8976"
                    role="button"
                    className="page_btn"
                  >
                    CONTACT US
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services" id="services">
        <div className="service_inner pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="section_title text-center">
                  <strong>See Our Featured Services</strong>
                </h2>
                <div className="underline"></div>
                <Services />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about_bottom" className="mt-5 mb-5">
        <div className="container about_bottom_bg">
          <div className="row" style={{ position: "relative", zIndex: "999" }}>
            <div className="col-md-12">
              <h2 className="section_title text-center mt-5 mb-5">
                <strong>About Us</strong>
              </h2>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <p className="mb-2">
                Welcome to Perfect Nails & Spa Salon! We are a full-service nail
                and spa salon located in Puyallup, WA. Our team of skilled
                technicians are dedicated to providing you with the highest
                quality services and a relaxing, pampering experience.
              </p>
              <p>
                Our services include a wide range of nail care options, such as
                pedicures, manicures, acrylic nails, gel nails, and shellac. We
                also offer a variety of spa packages, including paraffin wax
                treatments and relaxing massages. In addition to our standard
                services, we also offer a variety of specialized nail art
                designs to help you express your personal style.
              </p>
            </div>
            <div className="col-md-5">
              <p className="mb-2">
                At Perfect Nails & Spa Salon, we are committed to using only the
                highest quality products to ensure the health and beauty of your
                nails. We maintain a clean and welcoming atmosphere and strive
                to exceed our clients' expectations with each visit.
              </p>
              <p>
                Thank you for considering us for all of your nail and spa needs.
                We look forward to pampering you soon!
              </p>
            </div>

            <div className="col-md-1"></div>
            <div className="col-md-12 text-center"></div>
          </div>
        </div>
      </div>
      <Delux
        classes="pt-5 pb-5 pe-5"
        imgSrc="/img/landing_img/about_third_bg.jpg"
        firstText="Indulge in the ultimate pampering experience with our luxurious
              herbal manicure. This service includes a traditional manicure with
              the added benefits of herbal products to nourish and nourish the
              skin. Your nails and cuticles will be carefully cared for, and any
              calluses will be gently removed. The treatment continues with a
              relaxing exfoliation using herbal gel and a soothing stone
              massage."
        secondText="The experience is completed with a nourishing herbal lotion
        massage, a paraffin treatment, and a pop of color with your
        choice of polish. To top it all off, we'll finish with a
        nourishing cream mask to leave your hands feeling soft and
        rejuvenated. This is the perfect way to relax and show some
        love to your hands and nails."
      />
      <Delux
        classes="pt-5 pb-5 pe-5 bg_change"
        imgSrc="/img/landing_img/about_fourth_bg.jpg"
        firstText="Elevate your spa experience with our luxurious herbal pedicure. This service begins with an invigorating foot soak using a scented spa package box to awaken the senses. Your nails and cuticles will be carefully cared for, and any calluses or ingrown toenails will be gently removed. Next, enjoy an exfoliating herbal gel base treatment followed by a soothing herbal mask and foot scrub. The pampering continues with a relaxing hot stone massage and a nourishing lotion massage. The treatment is completed with a paraffin treatment and a pop of color with your choice of polish."
        secondText="For an extra indulgent experience, upgrade to our deluxe package which includes a jelly spa treatment, extra hot stone massage, and additional massage for just $55. This is the ultimate way to relax and rejuvenate tired feet."
      />
      <div id="service_bottom" className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h2 className="section_title">
                <strong>
                  What Do You
                  <br />
                  Need?
                </strong>
              </h2>
            </div>
            <div className="col-md-4">
              <div className="d-flex align-items-center">
                <ul className="mt-4 mt-md-0 mb-4 mt-mb-0">
                  <li>
                    <p>Manicures</p>
                  </li>
                  <li>
                    <p>Acrylic Nails</p>
                  </li>
                  <li>
                    <p>Gel Nails</p>
                  </li>
                  <li>
                    <p>Pedicure</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <p className="mb-3">
                At Perfect Nails & Spa Salon, we offer a wide range of services
                to help you look and feel your best. Whether you're in need of a
                classic manicure, a set of acrylic or gel nails, a relaxing
                pedicure, or a quick waxing treatment, we have you covered. Our
                team of skilled technicians use high-quality products and
                techniques to ensure that you receive the best service possible.
                Plus, with our clean and welcoming atmosphere, you can feel
                comfortable and at ease during your visit. So whatever your
                beauty needs may be, we have you covered at Perfect Nails & Spa
                Salon.
              </p>
              <a
                href="tel:(253) 536-8976"
                role="button"
                className="page_btn mt-3"
              >
                CONTACT US
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
