import React from "react";

const Delux = ({ imgSrc, firstText, secondText, classes }) => {
  return (
    <div id="about_third" className={classes}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <img src={imgSrc} alt="" />
          </div>
          <div className="col-md-4">
            <h2 className="section_title pt-5 pb-4">
              <strong>
                Deluxe
                <br />
                Manicure
              </strong>
            </h2>
            <p>{firstText}</p>
          </div>
          <div className="col-md-4">
            <div className="right_s">
              <div>
                <p className="pt-5">{secondText}</p>
                <a
                  href="tel:(253) 536-8976"
                  role="button"
                  className="page_btn mt-5"
                >
                  CONTACT US
                </a>
              </div>
              {/* <div className="text-end mt-4">
                <img
                  className="corner_img mb-2 me-1"
                  src="/img/landing_img/about_third_corner.png"
                  alt=""
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delux;
