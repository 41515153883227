import React from "react";
import { Link } from "react-router-dom";
const ResetPassInstruction = () => {
  return (
    <section id="email-change-confirmation">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-4">
            <div className="title">
              <h1>Check your email</h1>
            </div>

            <div className="message text-center">
              <p className="black">
                We sent you an email with instructions to reset your password.
              </p>
            </div>
            <div className="verify-icon mb-3">
              <p style={{color:"grey"}}>
                If you havn't received this email within a few minutes, please
                check your spam folder.
              </p>
            </div>
            <div className="text-center">
                <Link to="/">Go Home</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassInstruction;
